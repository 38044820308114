<div class="view">
  @switch (shownView()) { @case ('first') {
  <h4>{{ "main-view.first.title" | transloco }}</h4>
  <p>{{ "main-view.first.sub-title" | transloco }}</p>
  <form [formGroup]="form" (submit)="next()" cypress-id="form">
    <div class="inputs">
      <div class="form-group form-floating" [class.invalid]="
          !form.controls.name.valid && form.controls.name.touched
        ">
        <input type="text" class="form-control" formControlName="name"
          [placeholder]="'main-view.first.form.name.label' | transloco" id="name" autocomplete="name"
          cypress-id="name-input" />
        <label for="email">{{
          "main-view.first.form.name.label" | transloco
          }}</label>
        <div class="validation">
          @if(form.controls.name.hasError('required')) {
          <p>
            {{
            "main-view.first.form.name.validation-error.required" | transloco
            }}
          </p>
          }
        </div>
      </div>
      <div class="form-group form-floating" [class.invalid]="
          !form.controls.email.valid && form.controls.email.touched
        ">
        <input type="email" class="form-control" formControlName="email"
          [placeholder]="'main-view.first.form.email.label' | transloco" id="email" autocomplete="email"
          cypress-id="email-input" />
        <label for="email">{{
          "main-view.first.form.email.label" | transloco
          }}</label>
        <div class="validation">
          @if(form.controls.email.hasError('required')) {
          <p>
            {{
            "main-view.first.form.email.validation-error.required" | transloco
            }}
          </p>
          } @if(form.controls.email.hasError('pattern')) {
          <p>
            {{
            "main-view.first.form.email.validation-error.pattern" | transloco
            }}
          </p>
          }
        </div>
      </div>
      <div class="form-group form-floating" [class.invalid]="
          !form.controls.phone.valid && form.controls.phone.touched
        ">
        <input type="tel" class="form-control" formControlName="phone"
          [placeholder]="'main-view.first.form.phone.label' | transloco" id="phone" autocomplete="tel"
          cypress-id="phone-input" />
        <label for="phone">{{
          "main-view.first.form.phone.label" | transloco
          }}</label>
        <div class="validation">
          @if(form.controls.phone.hasError('required')) {
          <p>
            {{
            "main-view.first.form.phone.validation-error.required" | transloco
            }}
          </p>
          } @if(form.controls.phone.hasError('pattern')) {
          <p>
            {{
            "main-view.first.form.phone.validation-error.pattern" | transloco
            }}
          </p>
          }
        </div>
      </div>
    </div>
  </form>
  <div class="actions">
    <button class="ct-btn ct-btn-themed-solid" cypress-id="next-button" [disabled]="!form.valid" (click)="next()">
      {{ "main-view.first.next-button" | transloco }}
    </button>
  </div>
  } @case ('second') {
  <h4>{{ "main-view.second.title" | transloco }}</h4>
  <div class="checkbox d-flex">
    <input type="checkbox" id="terms-accept" cypress-id="terms-accept-checkbox"
      (change)="termsAccepted.set(checkbox.checked)" #checkbox />
    <label for="terms-accept" [innerHTML]="
        'main-view.second.checkbox-label'
          | transloco
            : {
                companyName: themingService.design()?.companyName,
                tosLink: 'https://www.catch-talents.de/tos-applicants',
                privacyLink: 'https://www.catch-talents.de/datenschutz'
              }
      " cypress-id="terms-accept-checkbox-label">
    </label>
  </div>
  <div class="actions">
    <a (click)="$event.preventDefault(); shownView.set('first')" class="text-center" cypress-id="back-button">{{
      "main-view.second.prev-button" | transloco }}</a>
    <button class="ct-btn ct-btn-themed-solid mt-3" cypress-id="submit-button"
      [disabled]="!checkbox.checked || loading()" (click)="submit()">
      {{ "main-view.second.submit-button" | transloco }} @if(loading() === true)
      {
      <span class="spinner-border text-secondary" role="status"></span>
      }
    </button>
  </div>
  } }
</div>