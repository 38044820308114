export const environment = {
  version: '0-0-7',
  buildDate: '202412061135',
  environment: 'production',
  release: 'ff9222dd5582b0eaab67494ccfcfa9469978b30b',
  production: true,
  debug: false,
  baseUrl: 'https://leads.catch-talents.de',
  apiUrl: 'https://api.catch-talents.de',
  apiUrlFallback: 'https://api.catch-talents.de',
  companyBaseUrl: 'https://company.catch-talents.de',
  jobboardBaseUrl: 'https://jobboard.catch-talents.de',
};
